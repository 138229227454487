// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--oyEUz";
export var box__soGrR = "PlasmicFooter-module--box__soGrR--3hsTU";
export var box__tEXvJ = "PlasmicFooter-module--box__tEXvJ--1Lfvu";
export var box__xgqN5 = "PlasmicFooter-module--box__xgqN5--28Frb";
export var iconLink__wxS7Q = "PlasmicFooter-module--iconLink__wxS7Q--2WSBw";
export var svg__oTtAc = "PlasmicFooter-module--svg__oTtAc--2H449";
export var box__mTKs = "PlasmicFooter-module--box__mTKs--2M4ch";
export var link__isxGm = "PlasmicFooter-module--link__isxGm--4QTOi";
export var box__wqydg = "PlasmicFooter-module--box__wqydg--1Hob9";
export var link__zfoxl = "PlasmicFooter-module--link__zfoxl--1ovgN";
export var box__xlIwu = "PlasmicFooter-module--box__xlIwu--297ux";
export var box__p2Vz9 = "PlasmicFooter-module--box__p2Vz9--1yGvf";
export var box__r7Vi0 = "PlasmicFooter-module--box__r7Vi0--2QnhH";
export var link__hacD0 = "PlasmicFooter-module--link__hacD0--2dEq3";
export var link__dsYs5 = "PlasmicFooter-module--link__dsYs5--1dpxd";
export var link__pyFtJ = "PlasmicFooter-module--link__pyFtJ--71kvS";
export var link___57Br = "PlasmicFooter-module--link___57Br--2DHTQ";
export var link__p4Pca = "PlasmicFooter-module--link__p4Pca--vP-65";
export var box__voThJ = "PlasmicFooter-module--box__voThJ--18QgV";
export var box__wlXi5 = "PlasmicFooter-module--box__wlXi5--34RiO";
export var link__qrHi = "PlasmicFooter-module--link__qrHi--2ruvh";
export var link__c4BoE = "PlasmicFooter-module--link__c4BoE--3JsFN";
export var link__esv4 = "PlasmicFooter-module--link__esv4--26oIj";
export var link___906OO = "PlasmicFooter-module--link___906OO--3IjrX";
export var link___2KcdH = "PlasmicFooter-module--link___2KcdH--2nkg6";
export var box__o4PD6 = "PlasmicFooter-module--box__o4PD6--ot7bS";
export var box__qeiE5 = "PlasmicFooter-module--box__qeiE5--2krpR";
export var link___164OS = "PlasmicFooter-module--link___164OS--2HnBM";
export var link__zgr3M = "PlasmicFooter-module--link__zgr3M--1ves9";
export var link__wcSlm = "PlasmicFooter-module--link__wcSlm--osrXk";
export var link___8M7Rh = "PlasmicFooter-module--link___8M7Rh--3CG2Y";
export var link__mZedH = "PlasmicFooter-module--link__mZedH--14byy";
export var box__c54Kr = "PlasmicFooter-module--box__c54Kr--352rj";
export var box__xkBz = "PlasmicFooter-module--box__xkBz--2omd1";
export var box__l3TW8 = "PlasmicFooter-module--box__l3TW8--7p6IX";
export var box__wxdOh = "PlasmicFooter-module--box__wxdOh--248Wc";
export var textbox = "PlasmicFooter-module--textbox--1O3a3";
export var button = "PlasmicFooter-module--button--27DKR";
export var box__umy0X = "PlasmicFooter-module--box__umy0X--1tPYw";
export var box__kYoPd = "PlasmicFooter-module--box__kYoPd--2CgO0";
export var box__aHkGl = "PlasmicFooter-module--box__aHkGl--2_ONX";
export var link__gEwAu = "PlasmicFooter-module--link__gEwAu--YH8-l";
export var link__bX74Z = "PlasmicFooter-module--link__bX74Z--2pqXw";
export var box___32Yao = "PlasmicFooter-module--box___32Yao--3pY-k";
export var box__bi2Ob = "PlasmicFooter-module--box__bi2Ob--2lrWA";
export var iconLink__dlJmc = "PlasmicFooter-module--iconLink__dlJmc--2HYam";
export var svg__zhWiY = "PlasmicFooter-module--svg__zhWiY--2nKZf";
export var iconLink__jZfyn = "PlasmicFooter-module--iconLink__jZfyn--2hm5O";
export var svg__nnom = "PlasmicFooter-module--svg__nnom--1h811";
export var iconLink___0AWnq = "PlasmicFooter-module--iconLink___0AWnq--3YD-h";
export var svg__qWtx = "PlasmicFooter-module--svg__qWtx--237lw";